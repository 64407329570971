/* .slide {
    height: 268px;
    width: 280px;
    margin-right: 0;
  }
  
  .title {
    width: 287px;
    text-align: left;
    font-weight: bold;
  }
*/  
  .card {
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    width: 100%;
    justify-content: center;
  }
 /* 
  .slide-container {
    display: flex;
    flex-direction: row;
    height: 268px;
    width: 280px;
    margin-right: 0;
  }
  
  .button {
    border: 0;
    margin: 0;
    font-size: 20px;
    height: 276px;
  }
  */
  .slide-img {
    width: 100%;
    margin-bottom: 0;
  }
  @media screen and (min-width: 550px) {
    .slide-img {
      /* width: 680px; */
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 575px) {
    .slide-img {
      /* width: 680px; */
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 650px) {
    .slide-img {
      /* width: 680px; */
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 700px) {
    .slide-img {
      /* width: 680px; */
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 768px) {
    .slide-img {
      /* width: 680px; */
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 900px) {
    .slide-img {
      width: 680px;
      margin-bottom: 0;
    }
  }
  /*
  .slide-text {
    text-align: center;
    margin: 0;
    font-style: normal;
    font-weight: bold;
  }
  
  .button-icon {
    margin-bottom: 35px;
  } */